<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <router-link to="/profile/education" type="button">
          <i class="dropdown-icon si si-arrow-left"></i>
        </router-link>
        <h3 class="card-title">Yeni Eğitim</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <label class="form-label">Eğitim Başlık</label>
              <input
                type="text"
                class="form-control"
                name="title"
                v-model="title"
                placeholder="Başlık"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button
          type="button"
          @click="save"
          id="saveButton"
          class="btn btn-primary float-right"
        >
          Eğitim Taslağını Oluştur
        </button>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  created() {
    document.title = "Yeni Eğitim";
    if(this.$store.state.user_type!='EXPERT' && this.$store.state.trainer!=1){
      this.$router.push("/profile");
    }
  },
  data() {
    return {
      title: null,
    };
  },
  methods: {
    save() {
      let title = this.title;
      if (title == null || title == "") {
        this.$vToastify.warning("Başlık girilmedi", "Uyarı!");
      } else {
        document.getElementById("saveButton").disabled = true;
        document.getElementById("saveButton").innerHTML =
          "Taslak oluşturuluyor..";
        let educationCreate = {
          title: title,
        };
        this.$store
          .dispatch("educationCreate", educationCreate)
          .then((value) => {
            if (value.type == "error") {
              if (value.message == "ERRxUNT") {
                this.$vToastify.warning(
                  "Kullanıcı eğitici statüsünde değil!",
                  "Uyarı!"
                );
                document.getElementById("saveButton").disabled = true;
                document.getElementById("saveButton").innerHTML =
                  "İşlem Başarısız";
              }
            } else {
              this.$vToastify.success(
                "Eğitim taslağı oluşturuldu",
                "Başarılı!"
              );
              this.$router.push(
                "/profile/education/update/" + value.created_id
              );
            }
          });
      }
    },
  },
  components: {},
  mounted() {
    
  },
};
</script>